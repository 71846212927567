
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnInit, Component, OnDestroy, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject, Observable, of as observableOf } from "rxjs";
import { takeUntil, take, debounceTime, filter } from "rxjs/operators";
import { CalendarFolder, CalendarFolderFlatNode } from "src/app/common/models/calendar.model";
import { FlatTreeControl } from "@angular/cdk/tree";
import { CalendarConstants } from "src/app/common/utils/calendar-constants";
import { TranslateService } from "@ngx-translate/core";
import { CalenderUtils } from "src/app/calendar/utils/calender-utils";
import { ToastService } from "src/app/common/providers/toast.service";
import { CreateCalendarFolderComponent } from "src/app/calendar/calendar-create-folder/calendar-create-folder.component";
import { CalendarRepository } from "src/app/calendar/repositories/calendar.repository";
import { CalendarState } from "src/app/reducers/calendar.reducer";
import { CommonService } from "src/app/services/ common.service.";
import { getCalendarFolders } from "src/app/reducers";
import { MatTreeFlattener, MatTreeFlatDataSource } from "@angular/material/tree";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { VncLibraryService } from "vnc-library";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { FormControl } from "@angular/forms";
import { UserProfile } from "src/app/shared/models";
import { getUserProfile } from "src/app/reducers";
import { isArray } from "util";
import { ElectronService } from "src/app/services/electron.service";
import { MailUtils } from "src/app/mail/utils/mail-utils";

@Component({
    selector: "vp-add-item-dialog",
    templateUrl: "./add-item-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddItemDialogComponent implements OnInit, OnDestroy {
    CalendarConstants = CalendarConstants;
    private isAlive$ = new Subject<boolean>();
    sourceFolder: CalendarFolder;
    calendarFolders: CalendarFolder[] = [];
    calendarTreeControl: FlatTreeControl<CalendarFolderFlatNode>;
    calendarTreeFlattener: MatTreeFlattener<CalendarFolder, CalendarFolderFlatNode>;
    calendarDataSource: MatTreeFlatDataSource<CalendarFolder, CalendarFolderFlatNode>;
    selectedFolder: CalendarFolder;
    isEventMove: boolean = false;

    searchControl: FormControl = new FormControl("", []);
    selectedCalenderFolder: any;
    rootCalendarFolders: any[] = [];
    currentSelected: any;
    appointment: any;
    currentUser: UserProfile;

    constructor(
        private store: Store<CalendarState>,
        public dialogRef: MatDialogRef<AddItemDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private changeDetectionRef: ChangeDetectorRef,
        private translate: TranslateService,
        private toastService: ToastService,
        private electronService: ElectronService,
        public mailService: MailService,
        private matDialog: MatDialog
    ) {
        this.calendarTreeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
            this._isExpandable, this._getChildren);
        this.calendarTreeControl = new FlatTreeControl<CalendarFolderFlatNode>(this._getLevel, this._isExpandable);
        this.calendarDataSource = new MatTreeFlatDataSource(this.calendarTreeControl, this.calendarTreeFlattener);
        this.store.select(getCalendarFolders).pipe(takeUntil(this.isAlive$)).subscribe(res => {
            if (!!res && res.length > 0) {
                const copyFolder = JSON.parse(JSON.stringify(res));
                this.calendarFolders = copyFolder;
                this.rootCalendarFolders = copyFolder;
                console.log("calendarFolders: ", this.calendarFolders);
                if (this.calendarDataSource) {
                    this.calendarDataSource.data = this.generateTree(copyFolder);
                }
                this.changeDetectionRef.markForCheck();
                this.calendarTreeControl.expandAll();
            }
        });
        this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(res => {
            let tempProfile = null;
            console.log("[getUserProfile] header", res);
            if (!!res) {
                tempProfile = res;
                tempProfile.email = this.getSingleEmail(res.email);
                this.currentUser = tempProfile;
            } else {
                const contactUser = this.electronService.isElectron
                    ? this.electronService.getFromStorage("profileUser") : localStorage.getItem("profileUser");
                if (!!contactUser) {
                    this.currentUser = MailUtils.parseUserProfile(contactUser);
                }
            }
        });

        this.sourceFolder = this.data.folder;
        this.appointment = this.data.appointment;
        if (this.data.isEventMove || this.data.isAppointmentMove) {
            this.isEventMove = true;
        }
        this.changeDetectionRef.markForCheck();
    }

    private _getLevel = (node: CalendarFolderFlatNode) => node.level;

    private _isExpandable = (node: CalendarFolderFlatNode) => node.expandable;

    private _getChildren(node: CalendarFolder): Observable<CalendarFolder[]> {
        let folders = node.folder || [];
        if (node.link) {
            folders = [...folders, ...node.link.map(v => v as CalendarFolder)];
        }
        return observableOf(folders);
    }

    hasChild = (_data: number, _nodeData: CalendarFolderFlatNode) => _nodeData.expandable;

    folderSelect(node: CalendarFolder): void {
        this.selectedFolder = node;
        this.changeDetectionRef.markForCheck();
    }

    ngOnInit() {
        this.searchControl.valueChanges.pipe(takeUntil(this.isAlive$), debounceTime(500)).subscribe(value => {
            this.filterChanged(value);
        });
    }

    ngOnDestroy(): void {
        this.isAlive$.next(false);
        this.isAlive$.complete();
        this.isAlive$.unsubscribe();
    }

    close() {
        this.dialogRef.close();
    }

    hasNestedChild = (_: number, nodeData: CalendarFolder) => (nodeData.folder && nodeData.folder.length > 0) || nodeData.link;
    transformer = (node: CalendarFolder, level: number) => {
        const flat = node as CalendarFolderFlatNode;
        flat.level = level;
        flat.expandable = !!node.folder;
        return flat;
    }

    filterChanged(filterText: string) {
        this.selectedFolder = undefined;
        if (filterText !== "") {
            const allFolders = [...CalenderUtils.getChildFolders(this.calendarFolders), ...this.calendarFolders];
            this.calendarDataSource.data = this.generateTree(
                allFolders.filter(f => f.name.toLowerCase().includes(filterText.toLowerCase()))
            );
        } else {
            this.calendarDataSource.data = this.generateTree(this.calendarFolders);
        }
        console.log("calendarFolderTree: ", this.calendarDataSource.data);
        this.calendarTreeControl.expandAll();
        this.changeDetectionRef.markForCheck();
    }

    submit(): void {
        this.dialogRef.close({ folderId: this.currentSelected.id });
    }


    generateTree(folders: CalendarFolder[]): any {
        let allFolders: CalendarFolder[] = [];
        let translatetion: string = "";
        this.translate.get("CALENDARS.CALENDARS").pipe(take(1)).subscribe((res: string) => {
            translatetion = res;
        });
        const allFolder = [{
            name: translatetion,
            rgb: "#f8f8f8",
            folder: folders,
            id: "1",
            l: "1",
            originalFolder: [],
            absFolderPath : "/calendar"
        }];
        return [...allFolder];
    }

  getParentFolder() {
    let folderId = this.selectedCalenderFolder.id;
    for (let i = 0; i < this.calendarFolders.length; i++) {
        if (this.calendarFolders[i].id === folderId) {
            this.selectedCalenderFolder = null;
        } else if (this.calendarFolders[i].folder && this.calendarFolders[i].folder.length) {
            this.findAndSelectSubFolder(folderId, this.calendarFolders[i], i);
        }
    }
  }

  findAndSelectSubFolder(folderId: any, subFolders: any, i?: number): void {
    subFolders.folder.forEach((subFolder: any, index) => {
        if (subFolder.id === folderId) {
            this.selectedCalenderFolder = subFolders;
        } else if (subFolder.folder) {
            this.findAndSelectSubFolder(folderId, subFolder, index);
        }
        });
    }

  getChildrens(folder: any) {
    this.selectedCalenderFolder = folder;
  }

    selectCurrent(folder) {
        console.log("calendarFolderTree select: ", this.isEventMove, folder, this.appointment);
        if (this.isEventMove) {
            // check if moveable to folder
            if (!folder.perm && folder.id !== "3") {
                // console.log("calendarFolderTree select2: ", this.currentUser.email, this.appointment.organizer, this.appointment.at);
                if ((this.appointment.id.indexOf(":") > -1) && !!this.appointment.at && ((this.appointment.at.length > 0) || (!!this.appointment.at.at && (this.appointment.at.at.length > 0))) ) {
                    this.toastService.show("CALENDARS.CANNOT_MOVE_DIFF_ORGANIZER_OWNER");
                    this.currentSelected = null;
                } else {
                    this.currentSelected = folder;
                }
            } else {
                if ((folder.perm) && (folder.perm.indexOf("a") > -1) && !!folder.owner && !!this.appointment.at && ((this.appointment.at.length > 0) || (!!this.appointment.at.at && (this.appointment.at.at.length > 0)))) {
                    this.toastService.show("CALENDARS.CANNOT_MOVE_DIFF_ORGANIZER_OWNER");
                    this.currentSelected = null;
                } else {
                    console.log("calendarFolderTree select3-1: ", folder.perm, folder.perm.indexOf("a"));
                    if (folder.perm && folder.perm.indexOf("a") < 0) {
                        // console.log("calendarFolderTree select3-1: => PERMISSION_DENIED_MSG", folder.perm);
                        this.toastService.show("PERMISSION_DENIED_MSG");
                        this.currentSelected = null;

                    } else {
                        // console.log("calendarFolderTree select3: ", this.currentUser.email, this.appointment.organizer, this.appointment.at);
                        if ((this.appointment.organizer !== this.currentUser.email) && !!this.appointment.at && ((this.appointment.at.length > 0) || (!!this.appointment.at.at && (this.appointment.at.at.length > 0))) ) {
                            this.toastService.show("CALENDARS.CANNOT_MOVE_DIFF_ORGANIZER_OWNER");
                            this.currentSelected = null;
                        } else {
                            this.currentSelected = folder;
                        }
                    }
                }
            }
        } else {
            if (!folder.perm && folder.id !== "3" ) {
                this.currentSelected = folder;
            } else {
                this.currentSelected = null;
            }
        }
    }

    createFolderNew(): void {
        console.log("this.selectedFolder", this.currentSelected);
        if (this.currentSelected === undefined || this.currentSelected === null) {
            this.currentSelected = null;
        }
        if (this.currentSelected !== null && this.currentSelected.id === "1") {
            this.currentSelected = null;
        }
        this.matDialog.open(CreateCalendarFolderComponent, {
            width: "480px",
            height: "200px",
            autoFocus: true,
            panelClass: "mail_folder-create_dialog",
            data: { targetFolder: this.currentSelected }
        });
    }

    getSingleEmail(emails) {
        if (isArray(emails)) {
            return emails[0];
        } else if (emails) {
            return emails;
        }
        return null;
    }

}
