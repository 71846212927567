
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, EventEmitter, Output, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { appRoutes, AppRoutes } from "./app.routes";
import { MailBroadcaster } from "../../../common/providers/mail-broadcaster.service";
import { BroadcastKeys } from "../../../common/enums/broadcast.enum";
import { getFederatedApps, getOnlineStatus, getZimbraFeatures } from "src/app/reducers";
import { MailRootState } from "src/app/mail/store";
import { Store, select } from "@ngrx/store";
import { take, distinctUntilChanged, takeUntil, filter } from "rxjs/operators";
import { CommonUtils } from "src/app/common/utils/common-util";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { InstallAppDialogComponent } from "../install-app/install-app.component";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { CommonRepository } from "src/app/mail/repositories/common-repository";
import { ConfigService } from "src/app/config.service";
import { ElectronService } from "src/app/services/electron.service";
import { AppState } from "src/app/reducers/app";
import { ZimbraFeatures } from "src/app/common/utils/zimbra-features";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "vp-app-switcher",
  templateUrl: "app-switcher.html"
})
export class AppSwitcherComponent implements OnDestroy {
  @Input() switchApp: boolean;
  @Output() closeSwitch = new EventEmitter();
  routes: AppRoutes[] = appRoutes;
  private isAlive$ = new Subject<boolean>();
  isUseDirectoryAuth: boolean = false;
  briefcaseImage: SafeResourceUrl;

  constructor(private router: Router,
    private store: Store<MailRootState | AppState>,
    private matDialog: MatDialog,
    private commonRepository: CommonRepository,
    private mailBroadcaster: MailBroadcaster,
    private domSanitizer: DomSanitizer,
    private electronService: ElectronService,
    private configService: ConfigService
  ) {
      this.store.select(getFederatedApps).pipe(filter(apps => !!apps && apps.length > 0), takeUntil(this.isAlive$)).subscribe(apps => {
        const routes = [];
        console.log("[AppSwitcherComponent][getFederatedApps]", apps);
        apps.forEach(app => {
            routes.push({
              title: app?.options?.title,
              icon: this.configService.get("publicVncDirectoryUrl") + app?.options?.icon,
              image: this.configService.get("publicVncDirectoryUrl") + app?.options?.image,
              nativeLink: `${app?.name?.toLowerCase()}://main`,
              path: app?.options?.url,
              position: app?.options?.position,
              name: app?.name,
              active: true,
              enabled: true
            });
        });
        /* Adding briefcase */
        const briefcaseName = "VNCbriefcase";
        this.briefcaseImage = this.domSanitizer.bypassSecurityTrustResourceUrl(CommonUtils.getBaseUrl() + "assets/icon/VNCbriefcase.svg");
        routes.push({
          title: briefcaseName.replace("vnc", "VNC"),
          name: briefcaseName.replace("vnc", "VNC"),
          icon: this.domSanitizer.bypassSecurityTrustResourceUrl(CommonUtils.getBaseUrl() + "/assets/icon/briefcase-new.svg"),
          nativeLink: `${briefcaseName.toLowerCase()}://main`,
          path: "",
          active: true,
          enabled: true
        });
        this.routes = routes;
      });
      this.configService.getAllConfig().pipe(takeUntil(this.isAlive$)).subscribe(res => {
        if (res.useVNCdirectoryAuth && res.useVNCdirectoryAuth === true) {
          this.isUseDirectoryAuth = true;
        } else {
          this.isUseDirectoryAuth = false;
        }
      });

      this.store.select(getZimbraFeatures).pipe(takeUntil(this.isAlive$)).subscribe(res => {
        const isZimbraBriefcaseEnabled = MailUtils.isZimbraFeatureEnabled(res, ZimbraFeatures.ZIMBRA_FEATURE_BRIEFCASE_ENABLED);
        if (!isZimbraBriefcaseEnabled) {
          const index = this.routes.findIndex((m: any) => m?.nativeLink.includes("vncbriefcase"));
          if (index !== -1) {
            this.routes.splice(index, 1);
          }
        }
      });
  }

  navigate(path: any) {
    this.mailBroadcaster.broadcast(BroadcastKeys.HIDE_SIDEBAR_DRAWER);
    this.router.navigate(path);
    this.closeSwitch.emit(false);
  }

  checkInstalledApp(uri: string, appName: string): Observable<boolean> {
    const subject = new Subject<boolean>();
    if (environment.isCordova) {
      if (CommonUtils.isOnAndroid()) {
        uri = CommonUtils.getPackageName(appName);
      }
      uri = uri.replace("main", "");
      console.log("[checkInstalledApp]", uri);
      appAvailability.check(
        uri, // URI Scheme
        () => {
          console.log("[checkInstalledApp] installed", appName);
          subject.next(true);
        },
        () => {
          console.log("[checkInstalledApp] not installed", appName);
          subject.next(false);
        }
      );
    } else {
      subject.next(true);
    }

    return subject.asObservable();
  }

  openApp(item): void {

    if (this.commonRepository.showNoInternetToastIfRequired()) return;
    console.log("APPSWITCH openApp: ", item, item.title.toLowerCase().includes("briefcase"), item.title.toLowerCase());
    if (item.title === "VNCmail") {
      // no need to re-open current app!
      this.closeSwitch.emit(false);
      return;
    }
    if (item.name === "vncchannels") {
      item.nativeLink = "vnctalk://talk/channels";
    }

    const path: string = !!item.path ? new URL(item.path).origin : "";
    const token = localStorage.getItem("token");
    if (["vncproject", "vncdirectory"].indexOf(item.name) > -1) item.path = `${path}/login`;
    if (["vnctalk", "vncmail", "vnccalendar", "vnccontacts", "vnctask", "vncchannels"].indexOf(item.name) > -1) item.path = (!!token) ? `${path}/api/deeplink/?jwt=` + token : `${path}/api/login`;

    this.mailBroadcaster.broadcast(BroadcastKeys.HIDE_SIDEBAR_DRAWER);
    if (item.title.toLowerCase() === "vnccontacts") {
      item.nativeLink = "vnccontacts://main";
      if (!this.isUseDirectoryAuth) {
        this.router.navigate(["/contacts"]);
      } else {
        if (!environment.isCordova) {
          if (this.electronService.isElectron) {
            try {
              let nativeHandler = this.electronService.app.getApplicationNameForProtocol(item.nativeLink);
              console.log("appswitcher nativeHandler: ", nativeHandler);
              if (nativeHandler && nativeHandler !== "") {
                this.electronService.openExternalUrl(item.nativeLink);
              } else {
                this.electronService.openExternalUrl(item.path);
              }
              this.closeSwitch.emit(false);
            } catch (e) {
              console.error("[AppSwitcherComponent] error: ", e);
            }
          } else {
            window.open(item.path, "_blank");
            this.closeSwitch.emit(false);
          }
        } else {
          const target = (CommonUtils.isOnCordova()) ? "_system" : "_blank";
          if (CommonUtils.isOnCordova() && ((CommonUtils.isOnAndroid() || CommonUtils.isOnIOS())) && (["vnccontacts", "vncchannels", "vncmail", "vnccalendar", "vnctalk", "vnctask"].indexOf(item.name) > -1)) {
            // native app exists, and we are on real mobile app
            const itemLink = (item.name === "vnccalendar") ? "vncmail://main/calendar" : item.nativeLink;
            this.checkInstalledApp(item.nativeLink, item.name).pipe(take(1)).subscribe(installed => {
              console.log("APPSWITCH checkinstalled: ", installed, item);
              if (installed) {
                cordova.InAppBrowser.open(item.nativeLink, target);
              } else {
                const url = CommonUtils.getAppStoreOrWeblink(item);
                cordova.InAppBrowser.open(url, target);
              }
            });
          } else {
            if (CommonUtils.isOnCordova()) {
              cordova.InAppBrowser.open(item.path, target);
            } else {
              window.open(item.path, target);
            }
          }
          this.closeSwitch.emit(false);
        }
      }
    } else if (item.title.toLowerCase().indexOf("briefcase") > -1) {
      console.error("[AppSwitcherComponent] navigate to briefcase: ", item);
      this.router.navigate(["/briefcase"]);
      this.closeSwitch.emit(false);
    } else if (item.title.toLowerCase() === "vnccalendar") {
      this.router.navigate(["/calendar"]);
      this.closeSwitch.emit(false);
    } else {
      if (!environment.isCordova || item.name.toLowerCase() === "vncproject") {
        if (this.electronService.isElectron) {
          try {
            let nativeHandler = this.electronService.app.getApplicationNameForProtocol(item.nativeLink);
            console.log("appswitcher nativeHandler: ", nativeHandler);
            if (nativeHandler && nativeHandler !== "") {
              this.electronService.openExternalUrl(item.nativeLink);
            } else {
              this.electronService.openExternalUrl(item.path);
            }
            this.closeSwitch.emit(false);
          } catch (e) {
            console.error("[AppSwitcherComponent] error: ", e);
          }
        } else if (environment.isCordova) {
          cordova.InAppBrowser.open(item.path, "_system");
          this.closeSwitch.emit(false);
        } else {
          window.open(item.path, "_blank");
          this.closeSwitch.emit(false);
        }
      } else {
        if (environment.isCordova) {
          const target = (CommonUtils.isOnCordova()) ? "_system" : "_blank";
          if ((CommonUtils.isOnAndroid() || CommonUtils.isOnIOS()) && (["vnccontacts", "vncchannels", "vncmail", "vnccalendar", "vnctalk", "vnctask"].indexOf(item.name) > -1)) {
            // native app exists, and we are on real mobile app
            const itemLink = (item.name === "vnccalendar") ? "vncmail://main/calendar" : item.nativeLink;
            this.checkInstalledApp(item.nativeLink, item.name).pipe(take(1)).subscribe(installed => {
              console.log("APPSWITCH checkinstalled: ", installed, item);
              if (installed) {
                cordova.InAppBrowser.open(item.nativeLink, target);
              } else {
                const url = CommonUtils.getAppStoreOrWeblink(item);
                cordova.InAppBrowser.open(url, target);
              }
            });
          } else {

            cordova.InAppBrowser.open(item.path, target);
          }

          this.closeSwitch.emit(false);
        } else {
          window.open(item.path, "_system");
          this.closeSwitch.emit(false);
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.closeSwitch.unsubscribe();
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  changeContactIcon(): void {
    /* Change contact icon and url if config useVNCdirectoryAuth = true */
    if (this.isUseDirectoryAuth) {
      if (!!this.routes && this.routes.length > 0) {
        this.routes.map( (a: any) => {
          if (a.title === "VNCcontacts") {
            a.icon = CommonUtils.getFullUrl("/assets/icon/VNCcontactsplus.svg");
            a.nativeLink = "vnccontactsplus://main";
          }
        });
      }
    }
  }
}
